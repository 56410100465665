.WellnessChallengeFeaturedImage__container {
  margin-right: -25px;
  position: relative;
  max-height: 1200px;
}

.WellnessChallengeFeaturedImage__container:after {
  content: "";
  width: 100%;
  height: 250px;
  opacity: 0.5;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  position: absolute;
  bottom: 0;
}

.WellnessChallengeFeaturedImage__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}
