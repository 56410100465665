@font-face {
  font-family: "Futura Std";
  src: url(./assets/FuturaStd-Book.otf);
  font-weight: normal;
}

@font-face {
  font-family: "Futura Std";
  src: url(./assets/FuturaStd-Bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: "Futura Std";
  src: url(./assets/FuturaStd-Heavy.otf);
  font-weight: 900;
}

body,
html,
#root {
  background: #f6f5f1;
  height: 100%;
}

.Application__featured_image_username {
  position: absolute;
  top: 50px;
  right: 60px;
  color: #ffffff;
  font-family: "Futura Std";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;
  text-transform: uppercase;
  text-shadow: 1px 1px 10px #333333;
}
.Application__featured_image_username_icon {
  vertical-align: bottom;
  margin-right: 5px;
  color: #ffffff;
}

.WellnessChallenge__before_title {
  opacity: 0.5;
  color: #000000;
  font-family: "Futura Std";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
}

.WellnessChallenge__title {
  color: #000000;
  font-family: "Futura Std";
  font-size: 44px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 47px;
}

.WellnessChallenge__headline {
  font-family: "Futura Std";
  font-weight: bold;
}

@media (max-width: 576px) {
  .WellnessChallenge__title {
    font-size: 48px;
    line-height: 58px;
  }
}

.WellnessChallenge__modal_before_title {
  color: #f04922;
  font-family: "Futura Std";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: uppercase;
  margin-bottom: 14px;
}

.WellnessChallenge__modal_title {
  color: #000000;
  font-family: "Futura Std", sans-serif;
  font-size: 48px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 58px;
  text-transform: uppercase;
  margin-bottom: 22px;
}

@media (max-width: 576px) {
  .WellnessChallenge__modal_title {
    font-size: 40px;
    line-height: 50px;
  }
}

.Application__main_content {
  max-width: 650px;
}

.WellnessChallenge__orange_elements {
  color: #f04922;
}
