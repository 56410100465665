.WellnessChallengeMainContent__description {
  color: #000000;
  font-family: "Futura Std", sans-serif;
  font-size: 18px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 29px;
}

.WellnessChallengeMainContent__select_charity ul {
  padding: 0;
}

.WellnessChallengeMainContent__select_charity ul li {
  list-style: none;
  margin-bottom: 12px;
}

.WellnessChallengeMainContent__select_charity_button_wrapper {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  border: 1px solid #f04922;
  border-radius: 4px;
  background-color: rgba(240, 73, 34, 0.25);
}

.WellnessChallengeMainContent__select_charity_button {
  height: 48px;
  width: 100%;
  text-indent: 20px;
  background: transparent;
  border: none;
  text-align: left;
  color: #000000;
  font-family: "Futura Std", sans-serif;
  font-size: 18px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 35px;
}

.WellnessChallengeMainContent__charity_confirm_button {
  padding: 9px 16px !important;
  font-size: 14px !important;
  line-height: 14px !important;
  letter-spacing: 1px !important;
  border-radius: 6px !important;
}

.WellnessChallengeMainContent__select_charity_button:focus {
  outline: none;
}

.WellnessChallengeMainContent__select_charity--has-selected
  .WellnessChallengeMainContent__select_charity_button_wrapper:not(.WellnessChallengeMainContent__select_charity_button_wrapper--selected) {
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
}

.WellnessChallengeMainContent__select_charity--has-selected
  .WellnessChallengeMainContent__select_charity_button.WellnessChallengeMainContent__select_charity_button--selected {
  color: #f04922;
}

.WellnessChallengeMainContent__percentage {
  margin-right: 22px;
  color: #000000;
  font-family: "Futura Std", sans-serif;
  font-size: 18px;
  font-weight: 900;
}
