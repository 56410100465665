.Navigation__container {
  background-color: transparent;
}

.Navigation__link {
  color: #000000;
  font-family: "Futura Std";
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
}

.Navigation__link:hover {
  color: #000000;
  text-decoration: none;
}
