.WellnessChallengeCharities__organizations_list {
  list-style: none;
  padding: 0;
}

.WellnessChallengeCharities__organizations_list li {
  margin-bottom: 24px;
}

.WellnessChallengeCharities__organization_name {
  color: #000000;
  font-family: "Futura Std";
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 19px;
  text-transform: uppercase;
}

.WellnessChallengeCharities__organization_description {
  opacity: 0.5;
  color: #000000;
  font-family: "Futura Std";
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 24px;
}
